import login from "views/Login";
import Dashboard from "views/Dashboard.js";
import Magasin from "views/Magasin";
import Articles from "views/Articles.js";
import UserProfile from "views/UserProfile";



var routes1 =[
    {
      path: "/login",
      name: "login",
      rtlName: "الرموز",
      icon: "tim-icons icon-atom",
      component: login,
      layout: "/admin",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/magasin",
        name: "Magasins",
        rtlName: "",
        icon: "tim-icons icon-atom",
        component: Magasin,
        layout: "/admin",
      }, 
      {
        path: "/articles",
        name: "Articles",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        component: Articles,
        layout: "/admin",
      }, 
      {
        path: "/user-profile",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        icon: "tim-icons icon-single-02",
        component: UserProfile,
        layout: "/admin",
      },
  ];


  export default routes1 ;