/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 SAGATEC (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by SAGATEC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router";
import axios from 'axios';
import Sidebar from "components/Sidebar/Sidebar.js";
import logo from "assets/img/react-logo.png";
import routes from "routes.js";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";


function UserProfile(props) {
  
  var IDmagasin ;
  const [confpassword , setconfpassword] = useState('');
  const [Newpassword , setNewpassword] = useState(''); 
  const [password , setpassword] = useState(''); 
  const [error, setError] = useState("");
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  
  axios.defaults.withCredentials = true;
  var seether= false;
  const logi= ()=>{
    axios.post("https://api.livecaisse.com/Update", {
      IDmagasin: IDmagasin,
      password: password,
    }).then((resp) =>{
     
      if(resp.data.length > 0){
        if(Newpassword==confpassword){
          axios.post("https://api.livecaisse.com/Update/mdp" , {
            Newpassword: Newpassword,
            IDmagasin: IDmagasin,
          }).then((resp1)=>{
            
                      setError("Mot de passe Modifié");
          })
        }else{
          setError("❌ Ces mots de passe ne correspondent pas. Veuillez réessayer.");
        }
        
      }else{
        setError("❌ Mot de passe incorrect");
      }
      
    });
  };
  if(props.location.state == undefined ){
    return <Redirect to="/admin/login" />
  };
  IDmagasin = props.location.state.detail;
  return (
    <>
    <Sidebar
               routes={routes}
               logo={{
                 outterLink: "",
                 text: "Dashboard",
                 imgSrc: logo,
               }}
               IDmagasin={IDmagasin}
               toggleSidebar={toggleSidebar}
             />
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Ancien mot de passe</label>
                        <Input
                          onChange={(e)=>{
                            setpassword(e.target.value);
                          }}
                          desaible
                          placeholder="Mot de passe"
                          type="text"
                        />
                        <p id="mdpIncorrecte">{error && <span className="error">{error}</span>}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Nouveau mot de passe</label>
                        <Input
                           onChange={(e)=>{
                            setconfpassword(e.target.value);
                          }}
                          placeholder="Nouveau mot de passe"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Confirmer mot de passe</label>
                        <Input
                           onChange={(e)=>{
                            setNewpassword(e.target.value);
                          }}
                          placeholder="Confirmer mot de passe"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={logi}>
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">

          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
