/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 SAGATEC (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by SAGATEC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import "./Login.css";
import { blue } from '@material-ui/core/colors';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {

  let history = useHistory();
  const classes = useStyles();
  const [username , setusername] = useState('');
  const [password , setpassword] = useState(''); 
  const [error, setError] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const jwt = require('jsonwebtoken');

  axios.defaults.withCredentials = true;
  var seether= false;
  const logi= ()=>{
    axios.post("https://api.livecaisse.com/login", {
      username: username,
      password: password,
    }).then((resp) =>{
      
      if(resp.data.length > 0){
        history.push({
          pathname: '/admin/magasin',
          
          state: { detail: resp.data }
        })
        setError("");
      }else if(resp.data==false)
      {
        
        setError("❌ Mot de passe ou nom d'utilisateur incorrect");
      }else if(resp.data == true)
      {
        setError("❌ votre abonnement a expiré merci de contacter votre instalateur pour le renouveller ");
      }
      
    });
  };
useEffect(()=>{
  axios.get("https://api.livecaisse.com/login").then((response)=>{
    if(response.data.loggedIn){
      setLoginStatus(response.data.user[0].Login)
    }
    
  });
}, []);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={
              {
                style:{
                  color :  "#B6B6B6"
                }
              }
            }
            InputProps={{style: {
              color: "#B6B6B6"
          }}}
            id="email"
            label="UserName"
            name="email"
            onChange={(e)=>{
              setusername(e.target.value);
            }}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={
              {
                style:{
                  color :  "#B6B6B6"
                }
              }
            }
            InputProps={{style: {
              color: "#B6B6B6"
          }}}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=>{
              setpassword(e.target.value);
            }}
          />
          
          <Button
            
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={logi}
          >
            Sign In
          </Button>
          <p id="mdpIncorrecte">{error && <span className="error">{error}</span>}</p>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}